import { Base } from "../commons/models/base";

export function removeFromArray(array: Array<Base>, removedItem: Base) {
    if (array) {
      let itemIndex = array.findIndex(item => item.id == removedItem.id);
      if (itemIndex > -1) {
        array.splice(itemIndex, 1);
      }
    }
    return array;
  }
  
  export function replaceInArray(array: Array<Base>, newItem: Base, addIfNotExists: boolean = true) {
    if (array) {
      let itemIndex = array.findIndex(item => item.id == newItem.id);
      if (itemIndex != -1) {
        array.splice(itemIndex, 1, newItem);
      } else {
        if (addIfNotExists) {
          array.push(newItem);
        }
      }
    }
  }
  
  export function addIfNotExists(array: Array<Base>, toBeAdded: Base) {
    if (array) {
      let itemIndex = array.findIndex(item => item.id == toBeAdded.id);
      if (itemIndex == -1) {
        array.push(toBeAdded);
      }
    }
    return array;
  }
  
  export function existsInArray(array: Array<Base>, toBeAdded: Base) {
    if (array) {
      let itemIndex = array.findIndex(item => item.id == toBeAdded.id);
      return itemIndex != -1;
    }
    return false;
  }
  
  export function getArrayDifference<T extends Base>(a1: T[], a2: T[]): T[] {
    if (!a1) return a2;
    if (!a2) return a1;
    return a1.filter(i1 => a2.findIndex(i2 => i2.id == i1.id) < 0);
  }