import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { ProductFamily } from '../../models/product';

@Injectable({
  providedIn: "root"
})
export class LaravelProductService {
  constructor(private httpClient: HttpClient) { }

  private get ROUTES() {
    return {
      list: environment.baseUrl + "/api/products",
      store: environment.baseUrl + "/api/product",
      delete: environment.baseUrl + "/api/product"
    };
  }

  public list(): Observable<any> {
    return this.httpClient.get(this.ROUTES.list);
  }

  public create(name: string, price: number, type: string, family: ProductFamily, description: string, editable: boolean) {
    let product = {
      name: name,
      price: price,
      type: type,
      description: description,
      editable: editable,
      family: family
    };
    return this.httpClient.post(this.ROUTES.store, { product: product });
  }

  public update(id: number, name: string, price: number, type: string, family: ProductFamily, description: string, editable: boolean) {
    let product = {
      id: id,
      name: name,
      price: price,
      type: type,
      description: description,
      editable: editable,
      family: family
    };
    return this.httpClient.put(this.ROUTES.store, { product: product });
  }

  public delete(id: number) {
    let params = new HttpParams().set("id", "" + id);
    return this.httpClient.delete(this.ROUTES.delete, { params });
  }
}
