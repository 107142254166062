import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class LaravelDeliverymenService {
  constructor(private httpClient: HttpClient) {}

  private get ROUTES() {
    return {
      list: environment.baseUrl + "/api/deliverymen",
      store: environment.baseUrl + "/api/deliveryman",
      inactive: environment.baseUrl + "/api/deliveryman/inactive"
    };
  }

  public list(): Observable<any> {
    return this.httpClient.get(this.ROUTES.list);
  }

  public create(name: string, last_name: string, tel: string) {
    let deliveryman = {
      name: name,
      last_name: last_name,
      tel: tel
    };
    return this.httpClient.post(this.ROUTES.store, { deliveryman: deliveryman });
  }

  public update(id: number, name: string, last_name: string, tel: string) {
    let deliveryman = {
      id: id,
      name: name,
      last_name: last_name,
      tel: tel
    };
    return this.httpClient.put(this.ROUTES.store, { deliveryman: deliveryman });
  }

  public setInactive(id) {
    return this.httpClient.post(this.ROUTES.inactive, { id: id });
  }
}
