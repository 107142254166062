import { Injectable } from '@angular/core';
import { DeliveryMan } from '../models/delivery-man';
import { LaravelDeliverymenService } from './laravel/laravel-deliverymen.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DeliverymenService {

  constructor(private laravelDeliveryManService: LaravelDeliverymenService) { }

  public createDeliveryMan(deliveryMan: DeliveryMan): Observable<DeliveryMan> {
    return this.laravelDeliveryManService.create(deliveryMan.name, deliveryMan.lastName, deliveryMan.tel).pipe(
      map(result => {
        return new DeliveryMan(result);
      })
    );
  }

  public updateDeliveryMan(deliveryMan: DeliveryMan): Observable<DeliveryMan> {
    return this.laravelDeliveryManService.update(deliveryMan.id, deliveryMan.name, deliveryMan.lastName, deliveryMan.tel).pipe(
      map(result => {
        return new DeliveryMan(result);
      })
    );
  }

  public getDeliveryMen(): Observable<Array<DeliveryMan>> {
    return this.laravelDeliveryManService.list().pipe(
      map(response => {
        let data = new Array<DeliveryMan>();
        response.forEach(element => {
          data.push(new DeliveryMan(element));
        });
        return data;
      })
    );
  }

  public deleteDeliveryMan(deliveryMan: DeliveryMan): Observable<DeliveryMan> {
    return this.laravelDeliveryManService.setInactive(deliveryMan.id).pipe(
      map(result => {
        return new DeliveryMan(result);
      })
    );
  }

}
