import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListResult } from '../../helpers/listResult.interface';
import { Product } from '../models/product';
import { LaravelProductService } from './laravel/laravel-product.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private laravelProductService: LaravelProductService) { }

  public getProducts(): Observable<ListResult<Product>> {
    return this.laravelProductService
      .list()
      .pipe(
        map(response => {

          let data = new Array<Product>();
          response.forEach(element => {
            data.push(new Product(element));
          });

          return {
            data: data,
            total: data.length
          };
        })
      );
  }

  public createProduct(product: Product): Observable<Product> {
    return this.laravelProductService.create(product.name, product.price, product.type, product.family, product.description, product.editable).pipe(
      map(result => {
        return new Product(result);
      })
    );
  }

  public updateProduct(product: Product): Observable<Product> {
    return this.laravelProductService.update(product.id, product.name, product.price, product.type, product.family, product.description, product.editable).pipe(
      map(result => {
        return new Product(result);
      })
    );
  }

  public deleteProduct(product: Product): Observable<Product> {
    return this.laravelProductService.delete(product.id).pipe(
      map(result => {
        return new Product(result);
      })
    );
  }
}
