import { Injectable, Component, Inject } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { Observable } from "rxjs";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material";
import { environment } from "../../../../../../environments/environment";

@Injectable()
export class RecapsGuard implements CanActivate {
  constructor(private router: Router, private dialog: MatDialog) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (environment["pin"]) {
      const dialogRef = this.dialog.open(RecapsDialog, {
        width: "300px",
        height: "320px",
        disableClose: true
      });

      return dialogRef.afterClosed();
    } else {
      return true;
    }
  }
}

@Component({
  selector: "recaps-dialog",
  templateUrl: "recap-dialog.component.html",
  styleUrls: ['./recap-dialog.component.scss'],
})
export class RecapsDialog {
    
  public pin;
  public errorMessageDisplay: boolean = false;

  constructor(public dialogRef: MatDialogRef<RecapsDialog>, @Inject(MAT_DIALOG_DATA) public data: string, public router: Router,) {}

  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key == 'Enter') {
      this.check();
    }
  }

  goHome(){
    this.dialogRef.close();
    this.router.navigate(["/dashboard"]);
  }

  check() {
    if (this.pin === environment["pin"]) {
      this.dialogRef.close(true);
    } else {
      this.errorMessageDisplay = true;
    }
  }
}
