import { Injectable } from '@angular/core';
import { ListResult } from '../../helpers/listResult.interface';
import { Product } from '../models/product';
import { Observable } from 'rxjs';
import { LaravelProductService } from './laravel/laravel-product.service';
import { map } from 'rxjs/operators';
import { LaravelIngredientService } from './laravel/laravel-ingredient.service';
import { Ingredient } from '../models/ingredient';

@Injectable({
  providedIn: 'root'
})
export class IngredientService {

  constructor(private laravelIngredientService: LaravelIngredientService) { }

  public getIngredients(): Observable<ListResult<Ingredient>> {
    return this.laravelIngredientService
      .list()
      .pipe(
        map(response => {
          
          let data = new Array<Ingredient>();
          response.forEach(element => {
            data.push(new Ingredient(element));
          });

          return { 
            data: data, 
            total: data.length 
          };
        })
      );
  }

  public createIngredient(ingredient: Ingredient): Observable<Ingredient> {
    return this.laravelIngredientService.create(ingredient.name, ingredient.additionPrice, ingredient.minusPrice, ingredient.description, ingredient.type).pipe(
      map(result => {
        return new Ingredient(result);
      })
    );
  }

  public updateIngredient(ingredient: Ingredient): Observable<Ingredient> {
    return this.laravelIngredientService.update(ingredient.id, ingredient.name, ingredient.additionPrice, ingredient.minusPrice, ingredient.description, ingredient.type).pipe(
      map(result => {
        return new Ingredient(result);
      })
    );
  }

  public deleteIngredient(ingredient: Ingredient): Observable<Ingredient> {
    return this.laravelIngredientService.delete(ingredient.id).pipe(
      map(result => {
        return new Ingredient(result);
      })
    );
  }

}
