import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class LaravelIngredientService {
  constructor(private httpClient: HttpClient) {}

  private get ROUTES() {
    return {
      list: environment.baseUrl + "/api/ingredients",
      store: environment.baseUrl + "/api/ingredient",
      delete: environment.baseUrl + "/api/ingredient"
    };
  }

  public list(): Observable<any> {
    return this.httpClient.get(this.ROUTES.list);
  }
  public create(name: string, additionPrice: number, minusPrice: number, description: string, type: string) {
    let ingredient = {
      name: name,
      additionPrice: additionPrice,
      minusPrice: minusPrice,
      description: description,
      type: type
    };
    return this.httpClient.post(this.ROUTES.store, { ingredient: ingredient });
  }

  public update(id: number, name: string, additionPrice: number, minusPrice: number, description: string, type: string) {
    let ingredient = {
      id: id,
      name: name,
      additionPrice: additionPrice,
      minusPrice: minusPrice,
      description: description,
      type: type
    };
    return this.httpClient.put(this.ROUTES.store, { ingredient: ingredient });
  }

  public delete(id: number) {
    let params = new HttpParams().set("id", "" + id);
    return this.httpClient.delete(this.ROUTES.delete, { params });
  }
}
